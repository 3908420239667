import { mapGetters } from 'vuex';
import TopRightSideMenu from '@/core/components/layouts/TopRightSideMenu.vue';
import { ProjectJobStatusEnum, UserVerificationStatusEnum } from '@/core/packages/shared-library';
// eslint-disable-next-line import/no-cycle
import { PROJECT_QUOTE_BUILDER_ROUTE } from '@/modules/project-quote-create/routes';
// eslint-disable-next-line import/no-cycle
import { PROJECT_QUOTE_OVERVIEW_ROUTE } from '@/modules/project-quote-overview/routes';
// eslint-disable-next-line import/no-cycle
import { PROJECTS_STORE } from '@/store/modules/projects';
// eslint-disable-next-line import/no-cycle
import { PROJECT_STAGES_TASKS_ROUTE } from '../../../modules/project-stages-tasks/routes';
export default {
    name: 'project-overview-menu',
    components: {
        TopRightSideMenu,
    },
    data: () => {
        return {
            UserVerificationStatusEnum,
        };
    },
    computed: {
        ...mapGetters(['isTradesperson', 'isProjectOwner']),
        ...mapGetters(PROJECTS_STORE, ['topRightSidebarMenu', 'selectedProjectJobStatus']),
        getCurrentRoute() {
            return this.$route.name;
        },
        getTopRightSidebarMenu() {
            const { topRightSidebarMenu, selectedProjectJobStatus } = this;
            const projectJobStatusId = selectedProjectJobStatus?.id;
            const filterTopRightSidebarMenu = JSON.parse(JSON.stringify(topRightSidebarMenu));
            const ifProjectJobStatusIdIsNotYetInProgress = [ProjectJobStatusEnum.AWAITING_QUOTES, ProjectJobStatusEnum.QUOTES_RECEIVED, ProjectJobStatusEnum.QUOTE_ACCEPTED];
            if (ifProjectJobStatusIdIsNotYetInProgress.includes(projectJobStatusId)) {
                const findProjectJobStagesRoute = filterTopRightSidebarMenu.find((menu) => menu.route === PROJECT_STAGES_TASKS_ROUTE);
                filterTopRightSidebarMenu.splice(filterTopRightSidebarMenu.indexOf(findProjectJobStagesRoute), 1);
            }
            const ifProjectJobStatusIdIsInProgress = [ProjectJobStatusEnum.QUOTE_CONFIRMED, ProjectJobStatusEnum.IN_PROGRESS, ProjectJobStatusEnum.COMPLETED];
            if (ifProjectJobStatusIdIsInProgress.includes(projectJobStatusId) || this.isTradesperson) {
                const findProjectQuoteOverviewRoute = filterTopRightSidebarMenu.find((menu) => menu.route === PROJECT_QUOTE_OVERVIEW_ROUTE);
                filterTopRightSidebarMenu.splice(filterTopRightSidebarMenu.indexOf(findProjectQuoteOverviewRoute), 1);
            }
            if (this.isProjectOwner
                || [ProjectJobStatusEnum.QUOTE_CONFIRMED, ProjectJobStatusEnum.IN_PROGRESS, ProjectJobStatusEnum.COMPLETED].includes(projectJobStatusId)) {
                const findProjectQuoteBuilderRoute = filterTopRightSidebarMenu.find((menu) => menu.route === PROJECT_QUOTE_BUILDER_ROUTE);
                filterTopRightSidebarMenu.splice(filterTopRightSidebarMenu.indexOf(findProjectQuoteBuilderRoute), 1);
            }
            return filterTopRightSidebarMenu;
        },
    },
    methods: {
        handleClick(route) {
            if (route !== this.$route.name) {
                this.$router.push({
                    name: route,
                    params: { projectRefId: this.$route.params.projectRefId }
                });
            }
        },
    }
};
