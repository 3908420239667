import { CirclePlusFilled, Finished, List, Message, ScaleToOriginal } from '@element-plus/icons';
import { defineComponent } from 'vue';
export default defineComponent({
    name: 'toprightside-menu',
    components: {
        ScaleToOriginal,
        Message,
        List,
        Finished,
        CirclePlusFilled,
    },
    emits: ['click'],
    props: {
        menuItems: {
            type: Array,
            required: true,
        },
        currentTab: {
            type: String,
            default: () => {
                return this.$route.name;
            }
        }
    },
    computed: {
        selectedTab() {
            return this.currentTab;
        }
    },
    methods: {
        handleClick(tab) {
            this.$emit('click', tab.paneName);
        },
    }
});
